.App {
  text-align: center;
  min-height: 100vh;
}

.App-header {
  height: 5vh;
  h1 {
    font-size: calc(10px + 2vmin);
  }
}

.App-link {
  color: #61dafb;
}

.user-location-prompt {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: x-large;
  .bold {
    font-weight: bold;
  }
}

.voronoi-container {
  width: 100vw;
  height: 95vh;

  .voronoi-map {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;

    .voronoi-diagram {
      width: 100%;
      height: 100%;
    }

    // Used to overlay divs and span the entire parent
    .layer {
      width: 100%;
      height: 100%;
      &.bottom {
        position: absolute;
        left: 0;
        top: 0;
      }
      &.top {
        z-index: 9;
        pointer-events: none;
        // opacity: 80%;
      }
    }
  }
}
